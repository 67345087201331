<template>
  <img :src="getImage()" :alt="alt" loading="lazy"/>
</template>

<script type="plain/text">
import FileService from "@/scripts/services/file.service";

export default {
  props: {
    fileId: null,
    alt: ""
  },
  data() {
    return {
      fileService: new FileService(),
      image: ""
    };
  },
  created() {
    this.renderImage();
  },
  methods: {
    renderImage() {
      this.fileService.render(this.fileId).then(this.renderCallback);
    },
    renderCallback(response) {
      this.image = response;
    },
    getImage() {
      return this.image;
    }
  }
};
</script>