<template>
  <div class="main-block footer">
    <v-layout>
      <div class="boxapp no-help">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <router-link :to="{ name: routes.app.ProjectList }">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </router-link>
          <!-- <a href="#" @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a>-->
          <h1>Galeria de Fotos</h1>
        </div>

        <!-- boxapp body -->
        <div class="box-main">
          <div class="section hero-preview">
            <!-- section head -->
            <div class="head">
              <h2>Hero Preview</h2>
              <p class="text-gray-dark">Landing Page {{projectLandingPageConfig.project.shortName}}</p>
            </div>

            <!-- section body -->
            <div class="wrapper">
              <v-layout row wrap>
                <v-flex preview>
                  <!-- Image background -->
                  <div
                    ref="heroImage"
                    class="image-bg"
                    :style="{backgroundImage: getImage(), opacity:projectLandingPageConfig.heroImageOpacity}"
                  ></div>

                  <!-- tools -->
                  <div
                    class="hero-tools left"
                    :class="{'preview-off': !heroPreview, 'preview-on': heroPreview}"
                  >
                    <!-- image opacity -->
                    <div class="sub-box slider" :class="{active:showHeroImageOpacitySlider}">
                      <v-slider
                        v-model="projectLandingPageConfig.heroImageOpacity"
                        min="0"
                        max="1"
                        step="0.1"
                        thumb-label="always"
                        color="#02ACB4"
                        @change="save()"
                      ></v-slider>
                    </div>
                    <!-- overlay opacity -->
                    <div class="sub-box slider" :class="{active:showHeroOverlayOpacitySlider}">
                      <v-slider
                        v-model="projectLandingPageConfig.heroOverlayOpacity"
                        min="0"
                        max="1"
                        step="0.1"
                        thumb-label="always"
                        color="#02ACB4"
                        @change="save()"
                      ></v-slider>
                    </div>
                    <a @click="changeHeroAlignment()">
                      <i
                        class="fal"
                        :class="{'fa-align-left':projectLandingPageConfig.heroAlignment==0, 'fa-align-center':projectLandingPageConfig.heroAlignment==1, 'fa-align-right':projectLandingPageConfig.heroAlignment==2}"
                      ></i>
                    </a>
                    <a @click="heroPreview = !heroPreview" :class="{active:heroPreview}">
                      <i class="fal fa-eye"></i>
                    </a>
                    <a @click="toggleHeroImageUpload()" :class="{active:showHeroUploadImage}">
                      <i class="fal fa-image"></i>
                    </a>
                    <!-- <a href>
                      <i class="fal fa-palette"></i>
                    </a>-->
                    <a
                      @click="toggleHeroImageOpacitySlider()"
                      :class="{active:showHeroImageOpacitySlider}"
                    >
                      <i class="custom-icon ci-contrast size-24"></i>
                    </a>
                    <a
                      @click="toggleHeroOverlayOpacitySlider()"
                      :class="{active:showHeroOverlayOpacitySlider}"
                    >
                      <i class="custom-icon ci-brightness size-24"></i>
                    </a>
                  </div>

                  <div
                    class="text-block"
                    :class="{'text-left':projectLandingPageConfig.heroAlignment==0, 'text-center':projectLandingPageConfig.heroAlignment==1, 'text-right':projectLandingPageConfig.heroAlignment==2}"
                  >
                    <h5>Projeto</h5>
                    <h1>{{projectLandingPageConfig.project.shortName}}</h1>
                    <h4>{{getCauseAndIncentiveLawText()}}</h4>
                    <p>{{projectLandingPageConfig.project.objective}}</p>
                    <v-btn
                      flat
                      round
                      color="white"
                      class="btn-green-light mx-0 mt-4"
                      large
                    >CLIQUE AQUI PARA DOAR</v-btn>
                  </div>
                  <div
                    class="overlay"
                    :style="{opacity:projectLandingPageConfig.heroOverlayOpacity}"
                  ></div>

                  <!-- dropzone -->
                  <div class="dropzone" :class="{active:showHeroUploadImage}">
                    <input type="file" accept="image/*" @change="heroImageChanged" />
                    <i class="fas fa-cloud-upload"></i>
                    <h4>ARRASTE E SOLTE</h4>
                    <p>
                      uma imagem aqui, ou
                      <span>procure</span>
                    </p>
                  </div>
                </v-flex>
                <v-flex dicas>
                  <div class="text-gray-dark">
                    <h4 class="text-red">
                      Dicas para upload
                      <i class="fal fa-lightbulb-exclamation"></i>
                    </h4>
                    <p>
                      Resolução mínima:
                      <b>1600x800px</b>.
                      <br />Resolução recomendada:
                      <b class="text-primary">2880 × 1300px</b>.
                      <br />Envie imagens em JPG com no máximo
                      <b>500KB</b>.
                    </p>
                    <p
                      class="mw-half"
                    >Não utilize imagens com grande quantidade de informação, grande quantidade de pessoas ou objetos, com pessoas muito distântes, de baixa resolução ou sem foco.</p>
                  </div>
                </v-flex>
              </v-layout>
            </div>

            <BorderSeparation />

            <div class="section image-grid">
              <!-- image counter -->
              <div class="pill gray right">{{fileProjectList.length}} fotos</div>

              <!-- section head -->
              <div class="head">
                <h2>Galeria de Fotos</h2>
              </div>

              <div style="position:relative">
                <div class="btn-upload fal fa-cloud-upload" v-if="fileProjectList.length < 10">
                  <input
                    type="file"
                    id="upload-activity"
                    accept="image/*"
                    @change="fileChanged"
                    ref="upload_activity"
                    multiple
                  />
                </div>
                <div class="wrapper">
                  <!-- Project image -->
                  <div class="item" v-for="(image, index) in fileProjectList" :key="image.id">
                    <div class="tools">
                      <a class="fal fa-search-plus" @click="openGalleryLightbox(index)"></a>
                      <a class="fal fa-trash" @click="openConfirmDeleteFileProjectModal(image.id)"></a>
                    </div>
                    <FileImage :file="image.file" />
                    <!-- <FileProjectImage :fileId="image.file.id" :alt="''" /> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AucAlert
        ref="confirmationComponent"
        title="Você tem certeza que deseja excluir esse arquivo?"
        confirmButtonText="Sim"
        cancelButtonText="Não"
        @confirm="deleteFile(selectedFileProjectId)"
      />
    </v-layout>
    <vue-easy-lightbox
      :visible="galleryLightboxVisible"
      :imgs="getGalleryLightboxImgs()"
      :index="galleryLightboxIndex"
      @hide="galleryLightboxVisible = false"
    ></vue-easy-lightbox>
    <div class="footer-step">
      <button
        type="button"
        @click="$router.push({name: routes.app.ProjectList})"
        class="btn-primary flex-inherit px-5 ml-auto v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text"
      >
        <div class="v-btn__content">Concluir</div>
      </button>
    </div>
  </div>
</template>

<script type="plain/text">
import BorderSeparation from "@/components/BorderSeparation.vue";
import FileProjectImage from "@/components/FileProjectImage.vue";
import AucAlert from "@/components/AucAlert.vue";
import { FileType } from "@/scripts/models/enums/fileType.enum";
import FooterStep from "@/components/FooterStep.vue";
import CommonHelper from "@/scripts/helpers/common.helper";
import FileProject from "@/scripts/models/fileProject.model";
import ProjectLandingPageConfig from "@/scripts/models/projectLandingPageConfig.model";
import FileService from "@/scripts/services/file.service";
import FileProjectService from "@/scripts/services/fileProject.service";
import ProjectService from "@/scripts/services/project.service";
import FileImage from "@/components/FileImage.vue";
import { Routes } from "@/scripts/models/enums/routes.enum";
import ImageCompressor from "image-compressor.js";
export default {
  components: {
    BorderSeparation,
    AucAlert,
    FileProjectImage,
    FooterStep,
    FileImage
  },
  props: ["projectId"],
  data() {
    return {
      fileType: FileType,
      fileProjectList: [],
      fileProject: new FileProject(),
      fileService: new FileService(),
      projectService: new ProjectService(),
      fileProjectService: new FileProjectService(),
      selectedFileProjectId: null,
      heroPreview: false,
      showHeroOverlayOpacitySlider: false,
      showHeroImageOpacitySlider: false,
      showHeroUploadImage: false,
      projectLandingPageConfig: new ProjectLandingPageConfig(),
      saving: false,
      saveQueue: [],
      galleryLightboxVisible: false,
      galleryLightboxIndex: 0,
      routes: Routes
    };
  },
  created() {
    this.projectService
      .getLandingPageConfigByProjectId(this.$route.query.projectId)
      .then(this.projectService_getProjectLandingPageConfigCallback);
    this.listPhotosGallery();
  },
  methods: {
    openGalleryLightbox(index) {
      this.galleryLightboxVisible = true;
      this.galleryLightboxIndex = index;
    },
    getGalleryLightboxImgs() {
      let imgs = [];
      if (this.fileProjectList == null) return [];
      for (let i = 0; i < this.fileProjectList.length; i++) {
        if (
          this.fileProjectList[i] != null &&
          this.fileProjectList[i].file != null
        )
          imgs.push(this.fileProjectList[i].file.path);
      }
      return imgs;
    },
    getCauseAndIncentiveLawText() {
      let result = "";
      if (this.projectLandingPageConfig.project != null) {
        if (this.projectLandingPageConfig.project.cause != null) {
          result += this.projectLandingPageConfig.project.cause.name;
        }
        if (this.projectLandingPageConfig.project.incentiveLaw != null) {
          if (this.projectLandingPageConfig.project.cause != null) {
            result += " - ";
          }
          result += this.projectLandingPageConfig.project.incentiveLaw.name;
        }
      }
      return result;
    },
    listPhotosGallery() {
      this.fileProjectService.listByProjectIdAndType(
        this.listFileProjectCallback,
        this.$route.query.projectId,
        this.fileType.ImagemGaleriaProjeto
      );
    },
    projectService_getProjectLandingPageConfigCallback(data) {
      this.projectLandingPageConfig = data;
    },
    changeHeroAlignment() {
      this.projectLandingPageConfig.heroAlignment =
        (this.projectLandingPageConfig.heroAlignment + 1) % 3;
      this.save();
    },
    toggleHeroImageOpacitySlider() {
      this.showHeroOverlayOpacitySlider = false;
      this.showHeroImageOpacitySlider = !this.showHeroImageOpacitySlider;
    },
    toggleHeroOverlayOpacitySlider() {
      this.showHeroImageOpacitySlider = false;
      this.showHeroOverlayOpacitySlider = !this.showHeroOverlayOpacitySlider;
    },
    toggleHeroImageUpload() {
      this.showHeroUploadImage = !this.showHeroUploadImage;
    },
    openConfirmDeleteFileProjectModal(id) {
      this.selectedFileProjectId = id;
      this.$refs.confirmationComponent.open();
    },
    listFileProjectCallback(data) {
      this.fileProjectList = data;
    },
    saveCallback(data) {
      this.listPhotosGallery();
    },
    deleteFileProjectCallback() {
      this.fileProjectService.listByProjectIdAndType(
        this.listFileProjectCallback,
        this.$route.query.projectId,
        this.fileType.ImagemGaleriaProjeto
      );
    },
    fileChanged(event) {
      var aux = 0;
      for (let i = 0; i < event.target.files.length; i++) {        
        var file = event.target.files[i];
        let reader = new FileReader();
        this.fileProject.file.name = file.name;
        this.fileProject.projectId = this.$route.query.projectId;
        reader.onload = async function() {
          this.fileProject.file.path = reader.result;
          this.fileProject.type = this.fileType.ImagemGaleriaProjeto;
          await this.fileProjectService.save(this.saveCallback, this.fileProject);
        }.bind(this);
        reader.readAsDataURL(file);
      }
    },
    deleteFile(fileProjectId) {
      this.fileProjectService.deleteFile(
        this.deleteFileProjectCallback,
        fileProjectId
      );
    },
    getImage(callback) {
      if (
        this.projectLandingPageConfig.heroFile.hasOwnProperty("imageContent")
      ) {
        return (
          "url(" + this.projectLandingPageConfig.heroFile.imageContent + ")"
        );
      } else {
        this.fileService
          .render(this.projectLandingPageConfig.heroFile.id)
          .then(this.fileService_renderHeroFileCallback);
      }
      return "";
    },
    fileService_renderHeroFileCallback(data) {
      this.$set(this.projectLandingPageConfig.heroFile, "imageContent", data);
    },
    heroImageChanged(event) {
      var file = event.target.files[0];
      let img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = function() {
        console.log(img.width + "x" + img.height);
        if (
          img.width < 1600 ||
          img.height < 800 ||
          img.width > 2880 || img.height > 1300
        ) {
          CommonHelper.swal(
            "Tamanho para upload da imagem deve ser entre 1600x800px e 2880x1300px. Sua imagem possui dimensões " +
              img.width +
              "x" +
              img.height
          );
        } else {
          new ImageCompressor(file, {
            convertSize: 409600,
            success: function(result) {
              let reader = new FileReader();
              this.projectLandingPageConfig.heroFile.name = result.name;
              reader.onload = function() {
                this.$refs.heroImage.style.backgroundImage =
                  "url(" + reader.result + ")";
                this.projectLandingPageConfig.heroFile.path = reader.result;
                this.projectLandingPageConfig.heroFile.imageContent =
                  reader.result;
                this.toggleHeroImageUpload();
                this.save();
              }.bind(this);
              reader.readAsDataURL(result);
            }.bind(this)
          });
        }
      }.bind(this);
    },
    save() {
      if (!this.saving && this.saveQueue.length == 0) {
        this.saving = true;
        this.projectService
          .saveLandingPageConfig(this.projectLandingPageConfig)
          .then(this.projectService_saveLandingPageConfigCallback)
          .catch(this.projectService_saveLandingPageConfigError);
      } else {
        this.saveQueue.push(this.projectLandingPageConfig);
      }
    },
    projectService_saveLandingPageConfigCallback(data) {
      this.projectLandingPageConfig.id = data.id;

      let imageContent = this.projectLandingPageConfig.heroFile.imageContent;
      this.projectLandingPageConfig.heroFile = data.heroFile;
      this.projectLandingPageConfig.heroFile.imageContent = imageContent;

      if (this.saveQueue.length != 0) {
        let plpc = this.saveQueue.shift();
        while (this.saveQueue.length > 0) plpc = this.saveQueue.shift();
        plpc.id = data.id;
        this.projectService
          .saveLandingPageConfig(plpc)
          .then(this.projectService_saveLandingPageConfigCallback)
          .catch(this.projectService_saveLandingPageConfigError);
      } else {
        this.saving = false;
      }
    },
    projectService_saveLandingPageConfigError(error) {
      console.log(error);
      this.saveQueue = [];
      this.saving = false;
    }
  }
};
</script>